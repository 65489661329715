export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPLOADING_FILE = "UPLOADING_FILE";
export const CHANGE_AVATAR = "CHANGE_AVATAR";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const ISNOT_UPLOADING = "ISNOT_UPLOADING";
export const SENT_EMAIL = "SENT_EMAIL";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATED_PASSWORD = "UPDATED_PASSWORD";
export const GET_INVITATIONINFO = "GET_INVITATIONINFO";
export const SENT_RECOVERYEMAIL = "SENT_RECOVERYEMAIL";
export const CONFIRM_TOKEN = "CONFIRM_TOKEN";
export const SIDEBAR = "SIDEBAR";
export const ADD_CONTACTO = "ADD_CONTACTO";
export const ADD_LEAD = "ADD_LEAD";

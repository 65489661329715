import isEmpty from "../validation/is-empty";

import { ADD_CONTACTO, ADD_LEAD } from "../actions/types";

const initialState = {
  contacto: {},
  lead: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CONTACTO:
      return { ...state, contacto: action.payload };

    case ADD_LEAD:
      return { ...state, lead: action.payload };

    default:
      return state;
  }
}

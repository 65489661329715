import {
  createTheme,
  adaptV4Theme,
  responsiveFontSizes,
} from "@mui/material/styles";
// colors
const primary = "#1d2b4f";
const secondary = "#5e6984";
const forty = "##2363f4";
const black = "#000";
const white = "#fff";
const darkBlack = "rgb(36, 40, 44)";
const background = "#fff";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// spacing
const spacing = 8;

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: primary },
      secondary: { main: secondary },
      terceary: { main: white },
      forty: { main: forty },
      common: {
        black,
        white,
        darkBlack,
        background,
      },
      warning: {
        light: warningLight,
        main: warningMain,
        dark: warningDark,
      },
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      background: {
        default: background,
      },
      spacing,
    },

    border: {
      borderColor: borderColor,
      borderWidth: borderWidth,
    },
    overrides: {
      MuiExpansionPanel: {
        root: {
          position: "static",
        },
      },
      MuiTableCell: {
        root: {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          borderBottom: `${borderWidth}px solid ${borderColor}`,
          [`@media (max-width:  600px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth,
        },
      },
      MuiAppBar: {
        root: {
          color: "#fff",
        },
      },

      MuiPrivateNotchedOutline: {
        root: {
          borderWidth: borderWidth,
        },
      },
      MuiListItem: {
        divider: {
          borderBottom: `${borderWidth}px solid ${borderColor}`,
        },
      },
      MuiDialog: {
        paper: {
          width: "100%",
          maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkBlack,
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          [`@media (max-width:  600px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        "Rubik",
        "Roboto",
        "Lato",
        "Raleway",
        "Helvetica",
        "Arial",
      ].join(","),
    },
  })
);

export default responsiveFontSizes(theme);
